import { getConfigs } from '../confs/store.conf';
import { isErrorPage } from './base.utils';
import loadScript from './load-script';
import { needsMerchantNav, loadMerchantNav } from './merchant-nav';

if (
	window.location.hostname === 'appsmartsandbox.byappdirect.com' ||
	window.location.hostname === 'testappsmart.appdirect.com' ||
	window.location.hostname === 'marketplace.appsmart.com'||
	window.location.hostname === 'catalog.appdirect.com'||
	window.location.hostname === 'catalogsandbox.byappdirect.com'||
	window.location.hostname === 'appsmart.cyanopica.appdirect-test.io' ||
	window.location.hostname === 'testcatalog.appdirect.com' ||
	window.location.hostname === 'des.test.devappdirect.me' ||
	window.location.hostname === 'dataops.test.devappdirect.me'
) {
	if(!isErrorPage()) {
		loadScript(`${location.origin}/appsmart-nav/appsmart-nav.js`, null, true)
	}
}

export const loadExternalNav = () => {
	const configs = getConfigs();
	if (
		(/.test.devappdirect.me/gi.test(window.location.hostname) && configs.CHANNEL_SETTINGS?.partner === 'APPSMART')
	) {
		if(!isErrorPage()) {
			loadScript(`${location.origin}/appsmart-nav/appsmart-nav.js`, null, true)
		}
	}

	if (
		configs.CHANNEL_SETTINGS.partner === 'APPSMART' || 
		configs.CHANNEL_SETTINGS.partner === 'APPSMARTSANDBOX'
	) {
		return true;
	}
	if (needsMerchantNav()) {
		loadMerchantNav();
		return true;
	}
	return false;
};
