import 'whatwg-fetch';
import { getConfigs, setConfigs } from './store.conf';
import { getCredentialsHeader } from '../utils/base.utils';

export const FLAGS = {
    REPLACE_MP_MANAGER_DASHBOARD: 'replace_mp_manager_dasboard',
    REPLACE_MP_MANAGER_DASHBOARD_NON_SELFSERVE: 'replace_mp_manager_dasboard_for_non_selfserve',
    PROGRAM_MANAGER_ENABLED: 'program_manager_enabled'
}

const getFlags = () => {
	const { marketUrlApi, partner } = getConfigs();
    const content = {
        "entities": [
            {
                "entityContext":{
                    "tenant": partner
                }
            }
        ],
        "flagKeys": [
            FLAGS.REPLACE_MP_MANAGER_DASHBOARD,
            FLAGS.PROGRAM_MANAGER_ENABLED,
            FLAGS.REPLACE_MP_MANAGER_DASHBOARD_NON_SELFSERVE
        ]
    }
	const apiUrl = `${marketUrlApi}/api/appconfigr/v1/evaluation/flagbatch`;
	return fetch(apiUrl, {
        method: 'POST',
        body: JSON.stringify(content),
        mode: 'cors',
        headers: {
            'Content-Type': 'application/json'
        },
        credentials: getCredentialsHeader(),
        cache: 'no-store' 
    }).then(resp => resp.ok ? resp.json() : undefined);
};

export default function initFlag() {
	return new Promise((resolve) => {
		getFlags().then((results) => {
            if(results && results.evaluationResults) {
                const flags = results.evaluationResults.reduce((acc, flag) => {
                    if(flag.flagKey) {
                        acc[flag.flagKey] = flag.variant;
                    }
                    return acc;
                }, {});
                setConfigs({ flags });
            }
			resolve();
		})
		.catch(() => {
			resolve();
		});
	});
}
